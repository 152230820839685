import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I had so many ideas for the next post, for the next YouTube video, for the making of my new website, for that side project that I loved so much…`}</p>
    <p>{`The reality is that I have no mental energy for that.`}</p>
    <p>{`I am here in the middle of Oslo (Norway) thinking about all the things that are happening in my beautiful country: Italy 🇮🇹.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1400/1*00OQVwQGW6yjQls3zp4kYQ.jpeg",
        "alt": "Image"
      }}></img></p>
    <p>{`So worried about my family and all the people in my country.`}</p>
    <p>{`I cannot go there.
I cannot do anything from here if not video calls to cheer up my family.`}</p>
    <p>{`I always advocate not focusing on what is not in your own control if you want to live a happy life.`}</p>
    <p>{`For the first time in my life, I feel that this virus, which is outside my control, is making me feel powerless. I can’t stop thinking about what is happening and when all of this will finish.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1400/1*NrpPScFUNNTX_09I54FeEw.jpeg",
        "alt": "Image"
      }}></img></p>
    <p>{`I just wish all of this to finish soon…`}</p>
    <p>{`It is not the article I hoped for, sorry for my rant.
Wash your hands, stay safe and at home. Wherever you are in the world.`}</p>
    <p>{`We are going to win this one!`}</p>
    <p>{`Tell me: `}<b>{`How are you coping with life at the moment?💓`}</b></p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      